<template>
  <div>
    <h2>Choose Appointment</h2>
    <b-alert variant="danger" show v-if="error">{{ error }}</b-alert>
    <b-table v-else striped :items="data" :fields="fields" :busy="loading">
      <template #cell(actions)="row">
        <b-button @click="selectPresentation(row.item)" variant="primary">Start</b-button>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <p v-if="!loading">If the appointment is not in the list above? <b-button variant="primary" @click="selectPresentation()">Skip selection</b-button></p>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  computed,
  inject
} from 'vue'
import { BvTableFieldArray } from 'bootstrap-vue/esm'
import moment from 'moment-timezone'
import { TIMEZONE } from './constants'
import { Appointment, useTodaysPresentationsQuery, PresentationInfoDocument, PresentationInfoQueryVariables, PresentationInfoQuery, ConsultantUserType, CurrentConsultantPersonalCalendarsDocument, CurrentConsultantPersonalCalendarsQuery, CurrentConsultantPersonalCalendarsQueryVariables } from './queries'
import { IrisGetters } from './store/types'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient } from '@apollo/client/core/index.js'

export default defineComponent({
  head: {
    title: "EstiaLabs - Choose Appointment",
  },
  setup () {
    const { $store, $bvModal } = getCurrentInstance()!.proxy as Vue

    const apolloClient = inject<ApolloClient<any>>(DefaultApolloClient)

    const { public: { sapphireSalesCalenderGroups }} = useRuntimeConfig()

    const sapphireCalendarUrls = computed<string[]>(() => {
      return sapphireSalesCalenderGroups.split(',').map(group => `https://calendly.com/${group}`)
    })

    const { onResult, onError, result, loading, error } = useTodaysPresentationsQuery({
      start: ($store.getters as IrisGetters).moment().startOf('day').toISOString(),
      end: ($store.getters as IrisGetters).moment().endOf('day').toISOString()
    })

    const data = computed(() => {
      if (result.value) {
        return result.value.appointments
      }
      return []
    })

    watchEffect(() => {
      if (result.value?.consultant.type === ConsultantUserType.Teacher) {
        $store.commit('updateField', {
          path: 'salesMode',
          value: 'sts'
        })
      }
    })

    const selectPresentation = (pres?: Appointment) => {
      return $bvModal.msgBoxConfirm(pres ? `Confirm selection of ${pres.lastName} - ${pres.postcode}?` : 'Skip selecting an appointment').then(resp => {
        if (resp) { // selected yes to continue
          return Promise.resolve().then(() => {
            if (pres || result.value.consultant.type === ConsultantUserType.Teacher) { // if selected a presentation/appointment we need to get the calendar info for this
              loading.value = true
              return Promise.resolve().then(() => {
                if (pres) {
                  return apolloClient.query<PresentationInfoQuery, PresentationInfoQueryVariables>({
                    query: PresentationInfoDocument,
                    variables: {
                      id: pres?.id
                    }
                  }).then((appointmentInfo) => {
                    if (
                      appointmentInfo.data.appointment.consultant.type === ConsultantUserType.Teacher
                      || (appointmentInfo.data.appointment.calendlyEventTypeBookingUrl && sapphireCalendarUrls.value.some(url => appointmentInfo.data.appointment.calendlyEventTypeBookingUrl.startsWith(url)))
                    ) {
                      $store.commit('updateField', {
                        path: 'salesMode',
                        value: 'sts'
                      })
                      return appointmentInfo.data.appointment.consultant.personalSubjectCalenders
                      // if either the current consultant is a Teacher OR the current consultant for the presentation is a Teacher OR the presentation calendar was a teacher type one.
                      // come other commit to save this info about this presentation
                    }
                  })
                }
                if (result.value.consultant.type === ConsultantUserType.Teacher) {
                  // if didn't selected a presentation BUT the current login is a TEACHER need to get personal calendars
                  return apolloClient.query<CurrentConsultantPersonalCalendarsQuery, CurrentConsultantPersonalCalendarsQueryVariables>({
                    query: CurrentConsultantPersonalCalendarsDocument
                  }).then(consultantInfo => {
                    // we know the current consultant is a teacher! we just need the calendaers
                    return consultantInfo.data.consultant.personalSubjectCalenders
                  })
                }
              }).then((calendars) => {
                if (calendars) {
                  // store calenders
                  $store.commit('updateField', {
                    path: 'calendarBookingOverride',
                    value: calendars
                  })
                }
                // presentationCalendarInfo.data.appointment.calendlyEventTypeBookingUrl
                // if either the current consultant is a Teacher OR the current consultant for the presentation is a Teacher OR the presentation calendar was a teacher type one.
                // come other commit to save this info about this presentation
              }).catch((e) => {
                return $bvModal.msgBoxOk(e.message, {
                  title: 'Error'
              }).then(() => Promise.reject(e))
              }).finally(() => {
                loading.value = false
              })
            }
          }).then(() => {
            // main save selected presentation
            return $store.commit('selectPresentation', pres)
          })
        }
      })
    }

    return {
      selectPresentation,
      data,
      loading,
      error,
      fields: [
        {
          formatter: (v) => moment.tz(v, TIMEZONE).format('hh:mm A'),
          key: 'appointmentStartTime',
          label: 'Time'
        },
        'lastName',
        'postcode',
        {
          key: 'actions',
          label: ''
        }
      ] as BvTableFieldArray
    }
  }
})
</script>
